import React from 'react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import { List, ThemeIcon } from '@mantine/core';
import PageHeader from '../../components/PageHeader';
import { CircleDashed } from 'tabler-icons-react';
import ClientStripe from '../../assets/images/circle-stripe.png';
import Footer from '../../components/Footer';
import ClinicDates from '../../components/ClinicDates';
import ClinicDates1 from '../../components/ClinicDates1';
// import UiTabsAccordions from '../../components/accordiontest';

const Content = () => {
  return (
    <section className='about-one '>
      <img src={ClientStripe} className='about-one__circle' alt='' />
      <div className='container text-center'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>Clinics in LUTH</h2>
        </div>
        <p className='about-one__text'>
          <List
            spacing='xs'
            size='sm'
            center
            icon={
              <ThemeIcon color='blue' size={24} radius='xl'>
                <CircleDashed size={16} />
              </ThemeIcon>
            }
          >
            <List.Item>Medical Out-Patient (MOP) I & II</List.Item>
            <List.Item>General Out-Patient Department (GOPD)</List.Item>
            <List.Item>Paediatric Out-Patient (POP)</List.Item>
            <List.Item>Surgical Out-Patient (SOP II)</List.Item>
            <List.Item>Out-Patient Pharmacy</List.Item>
          </List>
        </p>
      </div>
    </section>
  );
};


const AboutPage = () => {
  return (
    <Layout pageTitle='Clinics | LUTH'>
      <NavOne />
      <PageHeader title='Clinic Days' />
      <Content />
      {/* <ClinicDates /> */}
      <ClinicDates1 />
      {/* <UiTabsAccordions /> */}
      <Footer />
    </Layout> 
  );
};

export default AboutPage;
