import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../components/Layout';
// import NavOne from '../../components/NavOne';
// import PageHeader from '../../components/PageHeader';
// import Footer from '../../components/Footer';
import Tabs from '../components/Tabs';
// import adebayo from '../../assets/images/allied-departments/physiotherapy/adebayo.png';
// import adedipe from '../../assets/images/allied-departments/physiotherapy/adedipe.jpg';
// import adegoke from '../../assets/images/allied-departments/physiotherapy/adegoke.jpg';
// import akinbo from '../../assets/images/allied-departments/physiotherapy/akinbo.jpg';
// import chizoba from '../../assets/images/allied-departments/physiotherapy/chizoba.jpg';
// import eluemuno from '../../assets/images/allied-departments/physiotherapy/eluemuno.png';
// import gboyega from '../../assets/images/allied-departments/physiotherapy/gboyega.jpg';
// import olaniyan from '../../assets/images/allied-departments/physiotherapy/olaniyan.png';
// import omotola from '../../assets/images/allied-departments/physiotherapy/omotola.png';
// import sunday from '../../assets/images/allied-departments/physiotherapy/sunday.jpg';
// import rotimi from '../../assets/images/allied-departments/physiotherapy/rotimi.jpg';
// import team1 from '../../assets/images/team-1-1.jpg';

const ClinicDates1 = () => {
 
    const services = [
        {
        value1:'MONDAY-FRIDAY ',
        value2: '8AM-5PM  ',
        name: 'D.O.T (Direct Observe Therapy)',
        value:'DR. ABIOL A. ',
        },
        
      ];
    const Overview = [
        
            {
                value1:'MONDAY',
                value2: '8AM-4PM  ',
                name: 'ADULT ',
                value:'PROF AKANMU',
              },
              {
                value1:'TUESDAY',
                value2: '8AM-4PM  ',
                name: 'PAED/ADULT',
                value:'PROF. TEMIYE,  DR. AKINTAN, FAJOLU, EZENWA AND EZEAKA ',
              },
              {
                value1:'WEDNESDAY',
                value2: '8AM-4PM  ',
                name: 'ADULT/ADOLESCENT',
                value:'PROF. AKANMU ',
              },
              {
                value1:'THURSDAY ',
                value2: '8AM-4PM  ',
                name: 'ADULT',
                value:'PROF. AKANMU ',
              },
              {
                value1:'FRIDAY ',
                value2: '8AM-4PM  ',
                name: 'ADULT',
                value:'PROF. AKANMU ',
              },
        
    ]
    const Community = [
        
          {
            value1:'TUESDAY',
            value2: '8AM  ',
            name: 'IMMUNIZATION ',
            value:'PROF. OSIBOGUN, DR. ORIDOTA ',
          },
          {
            value1:'WEDNESDAY',
            value2: '8AM  ',
            name: 'IMMUNIZATION ',
            value:'PROF. ONAJOLE, DR. ROBERT ',
          },
          {
            value1:'THURSDAY ',
            value2: '8AM  ',
            name: 'IMMUNIZATION /MEASLES',
            value:' ',
          },
          {
            value1:'FRIDAY ',
            value2: '8AM  ',
            name: 'ENDERMIC ',
            value:'PROF. ONAJOLE  ',
          },
    
]
const OralMax = [
        
    {
      value1:'MONDAY',
      value2: '8AM  ',
      name: 'ORAL MAXILLOFACIAL SURGERY ',
      value:'PROF. GBOTOLORUN, PROF. AROTIBA, DR. OLURANTIMI, DR. ADAMSON  ',
    },
    {
      value1:'WEDNESDAY',
      value2: '8AM  ',
      name: 'CLEFT & PILLATE  ',
      value:'PROF. OGUNLEWE, PROF. ADEYEMO, DR. JAMES, DR. ADAMSON ',
    },
    {
      value1:'THURSDAY ',
      value2: '8AM  ',
      name: 'ORAL MAXILLOFACIAL & SURGERY (OMFS)',
      value:'PROF. OGUNLEWE, PROF. ADEYEMO, PROF. LADEINDE, DR. JAMES ',
    },


]
const psychiatry = [
        
    {
        value1:'MONDAY',
        value2: '8AM  ',
        name: 'ADULT ',
        value:'DR. SODIMU , PROF. ADEYEMI, DR. FATIREGUN, DR QUADRI',
      },
      {
        value1:'TUESDAY (YABA)',
        value2: '8AM  ',
        name: 'ADULT',
        value:'DR OSHODI, DR FADIPE, DR ADEGBITE ',
      },
      {
        value1:'WEDNESDAY',
        value2: '8AM  ',
        name: 'PSYCHOLOGY ',
        value:'DR. SODIMU, DR. OSHODI, MR OKEOWO (Psychologist), MRS OTTOH (Psychologist)',
      },
      {
        value1:'THURSDAY (YABA) ',
        value2: '8AM  ',
        name: 'ADULT',
        value:'PROF. ADEYEMI, PROF AINA, DR. FATIREGUN, DR SODIMU, DR QUADRI ',
      },
      {
        value1:'FRIDAY ',
        value2: '8AM  ',
        name: 'CHILD & ADOLESCENT ',
        value:'DR. OSHODI, DR ADEGBITE ',
      },

]

const SurgicalOP = [
        
  {
      value1:'MONDAY',
      value2: '8AM  ',
      name: 'UROLOGY ',
      value:'DR. JEJE , DR. OGUNJIMI ',
    },
    {
      value1:'TUESDAY (MORNING)',
      value2: '8AM',
      name: 'NEURO-SURGERY ',
      value:'DR. BANKOLE, DR. KANU, DR. OJO',
    },
    {
      value1:'',
      value2: '',
      name: 'HAETOLOGY',
      value:'DR. ADEYEMO, PROF. AKANMU, DR. OGBENNA, DR. OLOWOSELU ',
    },
    {
      value1:'TUESDAY (AFTERNOON)',
      value2: '12PM  ',
      name: 'BLOOD DISORDER ',
      value:'DR. ADEYEMO, DR. AKANMU ',
    },
    {
      value1:'',
      value2: '',
      name: 'ORTHOPAEDIC TEAM A',
      value:'DR. GIWA, DR. BADMUS, DR. ALABI, DR. BELIE',
    },
    {
      value1:'THURSDAY',
      value2: '8AM  ',
      name: 'BURNS AND PLASTIC',
      value:'DR. MOFIKOYA, DR. UGBURO  ',
    },
    {
      value1:'',
      value2: '8AM  ',
      name: 'TRAUMA ',
      value:'DR. ENWELUZOR, DR. AKINMOKUN   ',
    },
    {
      value1:'',
      value2: '11AM  ',
      name: 'PAED ORTHOPAEDIC  ',
      value:'DR. ENWELUZOR, DR. AKINMOKUN   ',
    },
    {
      value1:'',
      value2: '11AM  ',
      name: 'HAND ',
      value:'DR. MOFIKOYA, DR. ENWELUZOR    ',
    },
    {
      value1:'FRIDAY ',
      value2: '8AM  ',
      name: 'CARDIOTHORACIC',
      value:'DR. OLUSOJI, DR. OGUNLEYE',
    },
    {
      value1:'',
      value2: '',
      name: 'ORTHOPAEDIC TEAM B',
      value:'DR. GIWA, DR. BADMUS, DR. ALABI, DR. BELIE ',
    },

]
const medicaloutpatient = [
        
  {
      value1:'MONDAY',
      value2: '8AM  ',
      name: 'PULMONOLOGY General Respiratory Clinic ',
      value:'DR. DANIA , DR. OZOR',
    },
    {
      value1:'',
      value2: '12PM  ',
      name: 'GASTROENTEROLOGY & HAPATOLOGY, Hapititis Clinic  ',
      value:'DR. OYELEKE , DR. ODEGHE, DR. LESI ',
    },
    {
      value1:'',
      value2: '12PM  ',
      name: 'NEUROLOGY Headache And Movement Disorder  ',
      value:'PROF. OKUBADEJO , DR. OJO, DR. AGABI, DR. OJINI  ',
    },
    {
      value1:'TUESDAY (MORNING)',
      value2: '8AM  ',
      name: 'CARDIOLOGY Cardiology Team A',
      value:'PROF. MBAKWEM ,DR. AMADI, PROF. OKE, DR. KUSHIMO',
    },
    {
      value1:'WEDNESDAY (AFTERNOON)',
      value2: '12PM  ',
      name: 'PULMONOLOGY/ Airway Disease Clinic (Asthma & GOPD clinic)',
      value:'DR. DANIA, DR. OZOR',
    },
    {
      value1:'',
      value2: '',
      name: 'INFECTIOUS DISEASE Infectious Disease ',
      value:'DR. AKAEZE',
    },
    {
      value1:'THURSDAY ',
      value2: '8AM  ',
      name: 'Neurology Clinic',
      value:'PROF. OKUBADEJO, DR. OJO, DR. AGABI, DR. OJINI ',
    },
    {
      value1:'',
      value2: '',
      name: 'Neurology Clinic',
      value:'DR. OYELEKE, DR. ODEGHE, DR. LESI ',
    },
    {
      value1:'FRIDAY ',
      value2: '8AM  ',
      name: 'RENAL MEDICINE -Renal Clinic (Team A) ',
      value:'DR. MABAYOJE, DR.  BRAIMOH ',
    },

]
  const rows = services.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
      
    </tr>
  ));
  const rows1 = Overview.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));
  const rows2 = Community.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));
  const rows3 = OralMax.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));
  const rows4 = psychiatry.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));
  const rows5 = SurgicalOP.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));
  const rows6 = medicaloutpatient.map((element, ind) => (
    <tr key={ind}>
      <td>{element.value1}</td>
      <td>{element.value2}</td>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));
  // const nonRows = staffs.map((element) => (
  //   <tr key={element.name}>
  //     <td>{element.name}</td>
  //   </tr>
  // ));
  return (
    <Layout pageTitle='Physiotherapy Department | LUTH'>
    {/* //   <NavOne />
    //   <PageHeader title='Physiotherapy Department' /> */}
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      CLINIC DAYS AND TIME
                    </h2>
                  </div>
                </div>
                <Tabs>
                    <div label='Page 1'>
                    <Spoiler
                        maxHeight={300}
                        hideLabel='Hide'
                        showLabel='Show More'
                      >
                    <h2>D.O.T (Direct Observe Therapy) OUTPATIENT</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>CLINIC</th>
                          <th>CONSULTANT NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </Table>
                    <br />
                    <h2>COMMUNITY HEALTH OUTPATIENT</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>SPECIALITIES/UNIT</th>
                          <th>CONSULTANT NAME</th>11 
                          
                          
                        </tr>
                      </thead>
                      <tbody>{rows2}</tbody>
                    </Table>
                    <br />
                    <h2>COMMUNITY HEALTH OUTPATIENT</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>SPECIALITIES/UNIT</th>
                          <th>CONSULTANT NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rows3}</tbody>
                    </Table>
                    </Spoiler>
                  </div>
                
                  <div label='Page2'>
                    <div label='Overview'>
                      <Spoiler
                        maxHeight={300}
                        hideLabel='Hide'
                        showLabel='Show More'
                      >
                        <h2>CIHP (APIN)</h2>
                       <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>CLINIC</th>
                          <th>CONSULTANT NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rows1}</tbody>
                    </Table>
                    <br />
                    <h2>PSYCHIATRY  OUTPATIENT CLINICS</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>CLINIC</th>
                          <th>CONSULTANT NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rows4}</tbody>
                    </Table>
                    <h2>SURGICAL OUTPATIENT CLINIC (SOP I)</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>CLINIC</th>
                          <th>CONSULTANT NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rows5}</tbody>
                    </Table>
                    <h2>MEDICAL OUTPATIENT UNIT (I)</h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                        <th>DAY</th>
                        <th>TIME</th>
                          <th>SPECIALITIES/UNIT</th>
                          <th>CONSULTANT NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rows5}</tbody>
                    </Table>
                      </Spoiler>
                    </div>
                  </div>
                  {/* <div label='Staff'>
                    <React.Fragment>
                      
                     
                    </React.Fragment>
                  </div> */}

                  {/* <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:anaesthesia@luth.gov.ng'>
                        physiotherapy@luth.gov.ng
                        </a>
                      </p>
                    </React.Fragment>
                  </div> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </Layout>


    
  );
};

export default ClinicDates1;
